
if (typeof acf !== "undefined") {
    acf.addFilter("acf_blocks_parse_node_attr", function (shortcut, nodeAttr) {
        if (nodeAttr.name === "class" && nodeAttr.value.indexOf("iconify") !== -1) {
            return {
                name: nodeAttr.name,
                value: nodeAttr.value,
            };
        }

        return shortcut;
    });
}

/**
 * returns blocks on page, all on same level (instead of innerblocks)
 * @returns [{}]
 */
function wpSoupGetBlocksOnPage() {
    let blocks = [];
    // get all blocks, and call recursive function for it
    wp.data
        .select("core/block-editor")
        .getBlocks()
        .forEach((block) => {
            blocks = [...blocks, ...wpSoupGetBlocksFromBlock(block)];
        });
    return blocks;
}

/**
 * recursive method for wpSoupGetBlocksOnPage()
 * @param block
 * @returns [{}]
 */
function wpSoupGetBlocksFromBlock(block) {
    let blocks = [block];
    if (block.hasOwnProperty("innerBlocks")) {
        block.innerBlocks.forEach((inner_block) => {
            blocks = [...blocks, ...wpSoupGetBlocksFromBlock(inner_block)];
        });
    }
    return blocks;
}

if (wp.domReady !== undefined) {
    wp.domReady(() => {
        /**
         * Deprecated blocks
         * `wp_soup_deprecated_blocks` gets shared from php, it's a constant array
         * of strings
         */
        if (typeof wp_soup_deprecated_blocks !== "undefined") {
            const available_blocks = wp.blocks.getBlockTypes();
            const blocks_in_page = wpSoupGetBlocksOnPage();

            wp_soup_deprecated_blocks.forEach((deprecated_block) => {
                // check if the deprecated block is used on this page
                const current_result = blocks_in_page.find((block) => {
                    return block.name === deprecated_block;
                });

                // if it's on the page, set it in the deprecated blocks category
                // we do this because unregistering it would break the page.
                if (current_result !== undefined) {
                    const result = available_blocks.find((block) => {
                        return block.name === deprecated_block;
                    });
                    if (result !== undefined) {
                        result.category = "deprecated";
                    }
                }

                // if it's not used on the page, we can fully remove it instead
                else {
                    wp.blocks.unregisterBlockType(deprecated_block);
                }
            });
        }
    });
}
