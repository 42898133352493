import $ from "jquery";

if (wp && wp.data) {
    if (document.documentElement.clientWidth > 600 && document.body.classList.contains("custom-admin-styling")) {
        document.documentElement.style.paddingTop = "50px";
    }

    window.addEventListener("resize", function () {
        if (document.body.classList.contains("custom-admin-styling")) {
            if (document.documentElement.clientWidth > 600) {
                if (getComputedStyle(document.documentElement)["padding-top"] != "50px") {
                    document.documentElement.style.paddingTop = "50px";
                }
            } else {
                document.documentElement.style.paddingTop = "";
            }
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        const gutenbergLoadCheck = setInterval(() => {
            const anchors = $("[data-block] a");
            if (anchors.length > 0) {
                anchors.on("click", (e) => {
                    e.preventDefault();
                });

                clearInterval(gutenbergLoadCheck);
            }
        }, 100);

        const editPost = wp.data.select("core/edit-post");
        if (editPost) {
            editPost.isFeatureActive("fullscreenMode") &&
                wp.data.dispatch("core/edit-post").toggleFeature("fullscreenMode");
        }
    });
}

document.addEventListener(
    "DOMContentLoaded",
    () => {
        const epve = document.querySelector(".edit-post-visual-editor");
        if (epve !== null) {
            function getEditorWidth() {
                const width = epve.offsetWidth;
                document.body.setAttribute("style", `--editor-width: ${width - 2}px`);
            }

            getEditorWidth();
            window.addEventListener("resize", getEditorWidth, { passive: true });
        }
    },
    { passive: true }
);
